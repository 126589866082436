import BaseInstance from "../base.instance";
import {
  AUTH_LOGIN,
  AUTH_FORGOT_PASSWORD,
  AUTH_RESET_PASSWORD,
  AUTH_PROFILE,
  AUTH_CHANGE_PASSWORD
} from "../constants";

const AuthService = {
  login(body) {
    return BaseInstance.post(AUTH_LOGIN, body);
  },
  forgotPassword(body) {
    return BaseInstance.post(AUTH_FORGOT_PASSWORD, body);
  },
  resetPassword(body) {
    return BaseInstance.post(AUTH_RESET_PASSWORD, body);
  },
  getProfile(params) {
    return BaseInstance.query(AUTH_PROFILE, { params });
  },
  changePassword(body) {
    return BaseInstance.post(AUTH_CHANGE_PASSWORD, body);
  }
};

export default AuthService;
